<template>
    <div class="login">
        <div class="container">
            <LoginBox :LoginPageStyle="style"></LoginBox>
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            style: {
                "width": "330px",
                "margin-top": "50px",
                "margin-right": "10%",
                "float": "right",
                "padding": "25px",
            }
        }
    }
}

</script>

<style lang="less">
@import '@/assets/css/mixins.less';

body {
    min-width: 1200px;
}

.login {
    .container {
        // width: 688px;
        height: 600px;
        background-image: url(../assets/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

    }
}
</style>

